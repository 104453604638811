<apt-content-box [ngStyle.xs]="{ 'width-min.px': 390 }">
  <div
    fxLayout="column"
    style="margin-bottom: -60px"
    fxLayout.gt-sm="row"
    fxLayoutAlign="flex-start"
    class="content-container apt-negate-padding"
  >
    <div fxFlex class="content">
      <apt-h2 underline>{{ paymentType.startsWith('update-') ? 'Update ' : '' }}Payment Information</apt-h2>

      <ng-container *ngIf="chaseUID">
        <div class="update-card-wr" [ngStyle.xs]="{ 'margin-left.px': -32, 'margin-right.px': -21 }">
          <apt-chase-iframe
            iframeHeight="400px"
            [chaseUID]="chaseUID"
            (success)="onSuccess($event)"
            (cancel)="onCancel($event)"
            (error)="onError($event)"
            (validationStart)="onValidationStart()"
            (processing)="(processing)"
            [verbose]="true"
            [env]="env"
            [sandbox]="true"
          >
          </apt-chase-iframe>
        </div>
      </ng-container>
      <ng-container *ngIf="!chaseUID">
        <p>Loading <apt-text-waiter></apt-text-waiter></p>
      </ng-container>
    </div>

    <div fxHide.xs fxFlex="350px" class="right-panel content">
      <apt-h2>Support/Contact Information</apt-h2>

      <div class="contact-list">
        <div>Arch Customer Support</div>
        <div>customerservice&#64;archinsurance.com</div>
        <div>1 (844) 731-7067</div>
      </div>

      <ng-container *ngIf="errors && errors.length">
        <div style="margin-top: 16px">
          <apt-h2 underline>Errors</apt-h2>
        </div>
        <!-- <apt-inform state="error">{{errors.join(',')}}</apt-inform> -->
        <apt-inform *ngFor="let error of errors" state="error">{{ error.message }}</apt-inform>
      </ng-container>
    </div>
    <div
      *ngIf="chaseUID"
      fxFlex
      fxHide.gt-xs
      [ngStyle.xs]="{ 'margin-top.px': -60 }"
      class="right-panel"
      style="margin-left: 13px; margin-bottom: 20px; margin-right: 13px"
    >
      <apt-h2 underline>Support/Contact Information</apt-h2>

      <div class="contact-list content">
        <div>Arch Customer Support</div>
        <div>customerservice&#64;archinsurance.com</div>
        <div>1 (844) 731-7067</div>
      </div>

      <ng-container *ngIf="errors && errors.length">
        <div style="margin-top: 16px">
          <apt-h2 underline>Errors</apt-h2>
        </div>
        <!-- <apt-inform state="error">{{errors.join(',')}}</apt-inform> -->
        <apt-inform *ngFor="let error of errors" state="error">{{ error.message }}</apt-inform>
      </ng-container>
    </div>
  </div>
</apt-content-box>

<apt-dialog title="Sending Payment Information" modal="true" [(open)]="processing" [showCloseX]="false">
  <ng-container dialogBody>
    <p>Please wait <apt-text-waiter></apt-text-waiter></p>
  </ng-container>
</apt-dialog>

<apt-dialog title="Updated Payment Information" modal="true" [(open)]="cardUpdated" [showCloseX]="false">
  <ng-container dialogBody>
    <p>Your payment method was successfully updated.</p>
  </ng-container>
  <ng-container dialogFooter>
    <apt-button-footer>
      <ng-container buttonFooterRight>
        <apt-button [primary]="true" (click)="backToPrev()">Back to Policy Summary</apt-button>
      </ng-container>
    </apt-button-footer>
  </ng-container>
</apt-dialog>

<apt-dialog title="Payment Success" modal="true" [(open)]="paymentSuccess" [showCloseX]="false">
  <ng-container dialogBody>
    <p>Your payment was successful.</p>
  </ng-container>
  <ng-container dialogFooter>
    <apt-button-footer>
      <ng-container buttonFooterRight>
        <apt-button [primary]="true" (click)="backToPrev()">Back to Bond Summary</apt-button>
      </ng-container>
    </apt-button-footer>
  </ng-container>
</apt-dialog>
